var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _c(
              "vs-button",
              {
                staticClass: "xs:w-full sm:w-auto rounded-lg",
                attrs: { to: { name: _vm.exportRouteName } }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("tracks.tracks-list.export-tracks-csv")) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.tracks,
                stripe: true,
                draggable: false,
                sst: true,
                pagination: _vm.pagination
              },
              on: { action: _vm.handleAction, filter: _vm.handleFilter }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }